/** @jsx h */

import {h, render, Fragment} from "preact";
import {useState, useEffect} from 'preact/hooks';
import {Modal} from 'react-bootstrap';
import {translate} from '@elements/translations';
import {
    getLocaleDateString,
    getTimeString,
    noop,
    getSumOfObjectKeys,
    addParamsToUrl,
    fetcher, throwAlertMessage, getPriceGroupTitle, getRangeDays, addDays, getLocaleDateStringShort
} from "../../utils/utils.js";
import StepNav from "../step-nav";
import LoadingSpinner from "../loading-spinner";
import SkiTicketHeader from "../ski-ticket-config/ski-ticket-header";
import SkiTicketSectorSelection from "../ski-ticket-config/ski-ticket-sector-selection";
import SkiTicketDatepickerSelection from "./ski-ticket-datepicker-selection";
import SkiTicketVipPassSelection from "./ski-ticket-vip-pass-selection";
import {dateToISOString, localDateToUTCDate, UTCDateToLocalDate} from "@elements/date-utils";
import SkiTicketFooterBar from "../ski-ticket-config/ski-ticket-footer-bar";
import SkiTicketPricingSelection from "../ski-ticket-config/ski-ticket-pricing-selection";
import SkiTicketFinalSelection from "../ski-ticket-config/ski-ticket-final-selection";
import {getSkiTicketBaseConfig} from "../../utils/config-helper";
import SkiTicketSummary from "../ski-ticket-config/ski-ticket-summary";
import SectorMap from "../sector-map";
import {setCartCount} from "../../../load-cart";
import {
    usePricingConfigurations,
    usePricingSliderConfigurations,
    useServiceConfigurations
} from "../../utils/ski-ticket-configuration-hooks";
import { resetMaskModal } from '../../../microanimations-morphingmodal'


export default function SkiTicketModal({
    isLoading = false,
    isInEdit = false,
    id,
    editId,
    currentStepDefault = 0,
    isModalOpen = false,
    initiallyLoadPricingSlider = false,
    title = "",
    onModalHide = noop,
    firstView = "",
    firstViewInfo = {},
    hasSkilockerTeaser = false,
    onShowSkiLocker = noop,
    hasDatepickerView = false,
    hasPricingSlider = false,
    hasServiceView = true,
    hasVipPassView = false,
    dateDefault = "",
    dateStringDefault = "",
    dateToDefault = "",
    dateToStringDefault = "",
    sectorDefault = "",
    sectorDefaultTitle = "",
    ticketCounterDefault = {},
    productChoiceDefault = "",
    selectedOptionsDefault = {},
    showApplyServiceForAllTicketsDefault = true,
    vipPassesDefault = [],
    loadedVipPassesDefault = []
}) {
    // States
    const [currentStep, setCurrentStep] = useState(currentStepDefault); // 0 is initial state
    const [selectedDate, setSelectedDate] = useState(dateDefault);
    const [selectedDateString, setSelectedDateString] = useState(dateStringDefault);
    const [selectedToDate, setSelectedToDate] = useState(dateToDefault);
    const [selectedToDateString, setSelectedToDateString] = useState(dateToStringDefault);
    const [selectedSector, setSelectedSector] = useState(sectorDefault);
    const [selectedSectorTitle, setSelectedSectorTitle] = useState(sectorDefaultTitle);
    const [ticketCounter, setTicketCounter] = useState(ticketCounterDefault);
    const [selectedProductChoice, setSelectedProductChoice] = useState(productChoiceDefault);
    const [selectedOptions, setSelectedOptions] = useState(selectedOptionsDefault);
    const [showApplyServiceForAllTickets, setShowApplyServiceForAllTickets] = useState(showApplyServiceForAllTicketsDefault);
    const [configurationSuccess, setConfigurationSuccess] = useState(false);
    const [addToCartLoading, setAddToCartLoading] = useState(false);
    const [showPricingSlider, setShowPricingSlider] = useState(hasPricingSlider);
    const [showSectorMap, setShowSectorMap] = useState(false);
    const [allVipPassesValid, setAllVipPassesValid] = useState(false);
    const [vipPasses, setVipPasses] = useState(vipPassesDefault);
    const [loadedVipPasses, setLoadedVipPasses] = useState(loadedVipPassesDefault);

    const [skiLockerData, setSkiLockerData] = useState({});

    // needed for ticket-quickfinder to update default values
    useEffect(function() {
        if(sectorDefault) {setSelectedSector(sectorDefault);}
    }, [sectorDefault])

    useEffect(function() {
        if(sectorDefaultTitle) {setSelectedSectorTitle(sectorDefaultTitle);}
    }, [sectorDefaultTitle])

    useEffect(function() {
        if(currentStepDefault) {setCurrentStep(currentStepDefault);}
    }, [currentStepDefault])

    useEffect(function() {
        if(dateStringDefault) {
            setSelectedDateString(UTCDateToLocalDate(new Date(dateStringDefault)));
        }
    }, [dateStringDefault])

    useEffect(function() {
        if(dateDefault) {
            setSelectedDate(dateDefault);
        }
    }, [dateStringDefault])

    useEffect(function() {
        if(dateToStringDefault) {setSelectedToDateString(UTCDateToLocalDate(new Date(dateToStringDefault)));}
    }, [dateToStringDefault])

    useEffect(function() {
        if(dateToDefault) {setSelectedToDate(dateToDefault);}
    }, [dateToDefault])

    if(initiallyLoadPricingSlider && showPricingSlider) {
        //needed for ticket-quickfinder
        let paramsPricingSlider = {
            ticket_id: id,
            ...(selectedSector && { sector: selectedSector }),
            ...(selectedDate && { startDate: selectedDate }),
            ...(selectedToDate && { endDate: selectedToDate })
        }

        let {pricingSliderConfigurations, isLoadingPricing} = usePricingSliderConfigurations(paramsPricingSlider);
        if(pricingSliderConfigurations) {
            setShowPricingSlider(Object.keys(pricingSliderConfigurations.priceByDate).length > 0);
        }
    }

    let paramsServices = {
        ticket_id: id,
        ...(selectedDate && { startDate: selectedDate }),
        ...(selectedToDate && { endDate: selectedToDate }),
        ...(vipPasses && {vipPasses: vipPasses}),
        ...(selectedProductChoice && {productChoice: selectedProductChoice}),
        ...(selectedSector && { sector: selectedSector })
    }

    let {serviceConfigurations} = hasServiceView ? useServiceConfigurations(paramsServices) : {serviceConfigurations: null, isLoadingService: false};
    
    // Render
    if (isLoading) {
        return (
            <LoadingSpinner/>
        );
    }

    const resetValues = () => {
        //    TODO
        setCurrentStep(currentStepDefault);
        setSelectedDate(dateDefault);
        setSelectedDateString(dateStringDefault);
        setSelectedToDateString(dateToStringDefault);
        setSelectedToDate(dateToDefault);
        setSelectedSector(sectorDefault);
        setSelectedSectorTitle(sectorDefaultTitle);
        setTicketCounter(ticketCounterDefault);
        setSelectedProductChoice(productChoiceDefault);
        setSelectedOptions(selectedOptionsDefault);
        setShowApplyServiceForAllTickets(showApplyServiceForAllTicketsDefault);
        setConfigurationSuccess(false);
        setShowPricingSlider(hasPricingSlider);
        setShowSectorMap(false);
        setAddToCartLoading(false);
        setAllVipPassesValid(false);
        setVipPasses(vipPassesDefault);
        setLoadedVipPasses(loadedVipPassesDefault);
    }

    //calculate how many steps should be displayed
    function calculateSteps() {
        let steps = 3;
        if(firstView === 'datepicker') {steps--;}
        if(firstView === 'pricing-list') {steps--;}
        if(hasDatepickerView) { steps++; }
        if(hasServiceView || showPricingSlider) { steps++; }
        if(hasVipPassView) { steps++; }
        return steps;
    }

    function getBreadcrumbButton(text, changeToStep, hasLightFont = true) {
        return (
            <button
                onClick={() => {
                    setCurrentStep(+changeToStep);
                }}
                className={`btn btn-no-styling ticket-configuration__edit ${hasLightFont ? 'ticket-configuration__edit--light' : ''}`}>
                {text}
                <span aria-label="edit" className="icon icon-pen-outline ticket-configuration__edit-icon"/>
            </button>
        )
    }

    function getStepView() {
        let views = [];
        if(hasVipPassView) {
            views.push('vip-pass');
        }
        views.push(firstView);
        if(firstView !== 'datepicker' && hasDatepickerView) {
            views.push('datepicker');
        }
        if(firstView !== 'pricing-list') {
            views.push('pricing-list');
        }
        if(hasServiceView || showPricingSlider) {
            views.push('service-list');
        }
        return views;
    }

    function getBreadcrumb() {
        let views = getStepView();
        let breadcrumb = [];

        if(currentStep > 0) {
            for (let i = 0; i < currentStep; i++) {
                let view = views[i];
                if(view === 'sector-list') {
                    breadcrumb.push(getBreadcrumbButton(translate('ski-ticket.Sector') + " " +selectedSectorTitle, i, false));
                } else if(view === 'vip-pass') {
                    breadcrumb.push(getBreadcrumbButton(vipPasses.length + " " + translate('ski-ticket.vip-pass.vip passes'), i));
                } else if(view === 'datepicker') {
                    let dateString = getLocaleDateStringShort(selectedDateString);
                    if(selectedToDateString) {
                        dateString += (' - ' + getLocaleDateStringShort(selectedToDateString));
                    }
                    breadcrumb.push(getBreadcrumbButton(dateString, i));
                } else if(view === 'pricing-list') {
                    breadcrumb.push(getBreadcrumbButton(getSumOfObjectKeys(ticketCounter) + " " + translate('ski-ticket.tickets'), i));
                }
            }
        }
        return breadcrumb;
    }

    let params = {
        ticket_id: id,
        ...(selectedDate && { startDate: selectedDate }),
        ...(selectedToDate && { endDate: selectedToDate }),
        ...(vipPasses && {vipPasses: vipPasses}),
        ...(selectedProductChoice && {productChoice: selectedProductChoice}),
        ...(selectedSector && { sector: selectedSector })
    }

    let {pricingConfigurations} = usePricingConfigurations(params);

    function getPriceGroupsOfSelectedProductChoice() {
        let priceGroups = [];

        if(!pricingConfigurations.productChoices) {
            throwAlertMessage('danger', 'pricingConfigurations.productChoices', 'not defined!', true);
        } else {
            pricingConfigurations.productChoices.map(function (productChoice) {
                if(productChoice.id === selectedProductChoice) {
                    priceGroups =  productChoice.priceGroups;
                }
            });
        }

        return priceGroups;
    }


    const submitConfiguration = () => {
        setAddToCartLoading(true);
        let configurationData = {
            ticket_id: id,
            ...(editId && {edit_id: editId}),
            sector: selectedSector,
            vipPasses: vipPasses,
            startDate: selectedDate,
            endDate: selectedToDate,
            ticketCounter: ticketCounter,
            options: selectedOptions,
            productChoice: selectedProductChoice
        };
        let url = getSkiTicketBaseConfig().addToCart;


        let request = fetch(url, {
            method: 'POST',
            body: JSON.stringify(configurationData),
            headers: {
                'Content-Type': 'application/json'
            }
        });


        request.then(result => result.json()).then(result => {
            setCurrentStep(currentStep +1);
            if(result.success) {
                setConfigurationSuccess(true);
                setAddToCartLoading(false);
                if(result.itemsInCart) {
                    setCartCount(result.itemsInCart);
                }
                if(result.skiLockerConfig) {
                    setSkiLockerData(result.skiLockerConfig);
                }
                if (result['__trackingScript']) {
                    try {
                        eval(result['__trackingScript']);
                    } catch(e) {console.error(e)}
                }
            } else {
                showNotification(request);
                setAddToCartLoading(false);
            }
        }).catch(e => {
            console.log("CATCH", e);
            setAddToCartLoading(false);
            setConfigurationSuccess(false);
            let errorText = `<p><strong>ski-ticket-modal</strong>:add-to-cart failed!</p><p>${id}</p><p>${e}</p>`;
            throwAlertMessage('danger', 'add-to-cart', errorText, true);
        });
    }

    const handleModalHide = () => {
        onModalHide();
        resetMaskModal()
        
        const inLastStep = currentStep === calculateSteps() - 1
        if(inLastStep) {
            // animate the cart-icon on home
            const cartIcon = document.querySelector('.js-main-navbar-cart__icon')
            const cartAmount = document.querySelector('.js-load-cart__count')

            if(cartIcon && cartAmount) {
                cartIcon.classList.add('animate-cart-icon')
                cartAmount.classList.add('animate-cart-amount')
                cartIcon.addEventListener("animationend", () => {
                    cartIcon.classList.remove('animate-cart-icon')
                    cartAmount.classList.remove('animate-cart-amount')
                })
            }
        }

        function getCssVariable(name) {
            document.documentElement.style.getPropertyValue(name);
        }

        function removeUnit(value) {
            if(!value) return

            const unitsToRemove = ['px', 'rem', 'ms'];
            let result = value;
            unitsToRemove.forEach(unit => {
                result = result.replace(unit, '');
            });

            return result
        }
    }

    return (
        <Modal
            dialogClassName="modal-dialog modal-fullscreen modal-light-grey js-configurator-modal--morphing configurator-modal--morphing "
            show={isModalOpen}
            onHide={handleModalHide}
            backdrop={false}
        >

            <div className="ticket-configuration__header">
                <a href="/" className="pointer-events-auto ticket-configuration__brand">
                    <img width="238" height="100" alt="" src="/static/img/logos/verbier-4-vallees.svg"/>
                </a>

                <button type="button" className="close modal__close"
                        onClick={() => {
                            resetValues();
                            handleModalHide();
                        }}>
                    <span aria-hidden="true" className="icon icon-close"/>
                    <span className="modal__close-text">close</span>
                </button>

                <StepNav max={calculateSteps()} current={currentStep} onStepClick={setCurrentStep}
                         stepViews={getStepView()}/>
            </div>


            <div className="modal-content__inner">

                <div
                    className={`ticket-configuration ${currentStep === calculateSteps() - 1 ? '' : 'ticket-configuration--wide'}`}>

                    {getStepView()[currentStep] === 'sector-list' ? (
                        <Fragment>
                            <div className="row">
                                <div className="col">
                                    <SkiTicketHeader
                                        currentStep={currentStep}
                                        isFirstView={firstView === 'sector-list'}
                                        firstViewInfo={firstViewInfo}
                                        title={title}
                                        isInEdit={isInEdit}
                                        toptitle={translate('ski-ticket.datepicker.choose-sector')}
                                    />
                                </div>
                            </div>

                            {showSectorMap ? (
                                <SectorMap/>
                            ) : (
                                <Fragment>
                                    <SkiTicketSectorSelection
                                        selectedSector={selectedSector}
                                        id={id}
                                        onChangeSector={(sectorId, sectorTitle) => {
                                            setSelectedSector(sectorId);
                                            setSelectedSectorTitle(sectorTitle)
                                            setCurrentStep(currentStep + 1);
                                        }}
                                    />
                                </Fragment>
                            )}

                            <div
                                className="col-auto mt-3 mt-md-3 microanimations--fade microanimations--first-fold">
                                {showSectorMap ? (
                                    <button className="btn btn-outline-dark" onClick={() => {
                                        setShowSectorMap(false)
                                    }}>
                                        <span className="icon btn__icon-medium icon-pen-paper"/>
                                        {translate(`ski-ticket.show-sector-list`)}
                                    </button>
                                ) : (
                                    <button className="btn btn-outline-dark" onClick={() => {
                                        setShowSectorMap(true)
                                    }}>
                                        <span className="icon btn__icon-medium icon-map"/>
                                        {translate(`ski-ticket.show-sectors-on-map`)}
                                    </button>
                                )}
                            </div>

                        </Fragment>
                    ) : ""}

                    {getStepView()[currentStep] === 'vip-pass' ? (
                        <Fragment>
                            <SkiTicketHeader
                                currentStep={currentStep}
                                title={title}
                                isInEdit={isInEdit}
                                isFirstView={firstView === 'vip-pass'}
                                firstViewInfo={firstViewInfo}
                                toptitle={translate('ski-ticket.validate-vip-pass')}
                                subtitle={getBreadcrumb()}
                            />
                            <SkiTicketVipPassSelection
                                id={id}
                                vipPasses={vipPasses}
                                onChangeVipPasses={(vipPasses) => {
                                    setVipPasses(vipPasses)
                                }}
                                loadedVipPasses={loadedVipPasses}
                                onLoadedVipPasses={(loadedVipPasses) => {
                                    setLoadedVipPasses(loadedVipPasses)
                                }}
                                allVipPassesValid={allVipPassesValid}
                                onChangeAllVipPassesValid={(allVipPassesValid) => (setAllVipPassesValid(allVipPassesValid))}
                            />
                        </Fragment>
                    ) : ""}

                    {getStepView()[currentStep] === 'datepicker' ? (
                        <Fragment>


                            <SkiTicketHeader
                                currentStep={currentStep}
                                title={title}
                                isInEdit={isInEdit}
                                isFirstView={firstView === 'datepicker' || getStepView()[currentStepDefault] === 'datepicker'}
                                firstViewInfo={firstViewInfo}
                                toptitle={translate('ski-ticket.datepicker.choose-date')}
                                subtitle={getBreadcrumb()}
                            />

                            <SkiTicketDatepickerSelection
                                id={id}
                                selectedDate={selectedDate}
                                selectedDateString={selectedDateString}
                                selectedToDate={selectedToDate}
                                selectedSector={selectedSector}
                                selectedToDateString={selectedToDateString}
                                showPricingSlider={showPricingSlider}
                                vipPasses={vipPasses}
                                onReceiveEmptyPricing={(bool) => {
                                    setShowPricingSlider(!bool);
                                }}
                                onSelectPricingSliderDate={(date) => {
                                    let endDate = addDays(UTCDateToLocalDate(new Date(date)), getRangeDays(selectedDateString, selectedToDateString) - 1);
                                    setSelectedDate(date);
                                    setSelectedDateString(UTCDateToLocalDate(new Date(date)));
                                    setSelectedToDate(dateToISOString(localDateToUTCDate(endDate), false));
                                    setSelectedToDateString(endDate);
                                }}
                                onChangeDates={(dates) => {
                                    if (Array.isArray(dates)) {
                                        const [start, end] = dates;
                                        setSelectedDate(dateToISOString(localDateToUTCDate(start), false));
                                        setSelectedDateString(start);
                                        setSelectedToDate(dateToISOString(localDateToUTCDate(end), false));
                                        setSelectedToDateString(end);
                                    } else {
                                        setSelectedDateString(dates);
                                        setSelectedDate(dateToISOString(localDateToUTCDate(dates), false))
                                    }
                                }}
                            />
                        </Fragment>
                    ) : ""}


                    {getStepView()[currentStep] === 'pricing-list' ? (
                        <Fragment>
                            <SkiTicketHeader
                                currentStep={currentStep}
                                title={title}
                                isInEdit={isInEdit}
                                isFirstView={firstView === 'pricing-list'}
                                firstViewInfo={firstViewInfo}
                                toptitle={translate('ski-ticket.date-time-list.choose-quantity')}
                                subtitle={getBreadcrumb()}
                            />


                            <SkiTicketPricingSelection
                                title={title}
                                selectedDate={selectedDate}
                                selectedToDate={selectedToDate}
                                ticketCounter={ticketCounter}
                                selectedSector={selectedSector}
                                selectedProductChoice={selectedProductChoice}
                                vipPasses={vipPasses}
                                onChangeProductChoice={(item) => {
                                    setSelectedProductChoice(item)
                                }}
                                onChangeTicketCounter={(item) => {
                                    setTicketCounter(item)
                                }}
                                id={id}
                            />

                        </Fragment>
                    ) : ""}

                    {getStepView()[currentStep] === 'service-list' ? (
                        <Fragment>
                            <SkiTicketHeader
                                currentStep={currentStep}
                                title={title}
                                isInEdit={isInEdit}
                                isFirstView={firstView === 'service-list'}
                                firstViewInfo={firstViewInfo}
                                toptitle={translate('ski-ticket.service-options')}
                                subtitle={getBreadcrumb()}
                            />

                            <SkiTicketFinalSelection
                                id={id}
                                title={title}
                                selectedDate={selectedDate}
                                selectedToDate={selectedToDate}
                                vipPasses={vipPasses}
                                selectedSector={selectedSector}
                                selectedRangeInDays={getRangeDays(selectedDateString, selectedToDateString)}
                                onSelectDate={(date) => {
                                    let endDate = addDays(UTCDateToLocalDate(new Date(date)), getRangeDays(selectedDateString, selectedToDateString) - 1);
                                    setSelectedDate(date);
                                    setSelectedDateString(UTCDateToLocalDate(new Date(date)));
                                    setSelectedToDate(dateToISOString(localDateToUTCDate(endDate), false));
                                    setSelectedToDateString(endDate);
                                }}
                                hasServiceView={hasServiceView}
                                hasPricingSlider={showPricingSlider}
                                selectedOptions={selectedOptions}
                                onChangeOptions={(item) => {
                                    console.log('change');
                                    setSelectedOptions(item);
                                }}
                                selectedProductChoice={selectedProductChoice}
                                ticketCounter={ticketCounter}
                                selectedSectorTitle={selectedSectorTitle}
                                showApplyServiceForAllTickets={showApplyServiceForAllTickets}
                                onChangeShowApplyServiceForAllTickets={(item) => {
                                    setShowApplyServiceForAllTickets(item);
                                }}
                            />
                        </Fragment>
                    ) : ""}

                    {currentStep === calculateSteps() - 1 ? (
                        <Fragment>
                            {addToCartLoading ? <LoadingSpinner/> : ""}
                            <SkiTicketSummary
                                id={id}
                                isInEdit={isInEdit}
                                title={title}
                                vipPasses={vipPasses}
                                selectedDate={selectedDate}
                                selectedToDate={selectedToDate}
                                selectedSector={selectedSector}
                                selectedProductChoice={selectedProductChoice}
                                ticketCounter={ticketCounter}
                                selectedOptions={selectedOptions}
                                onShowSkiLocker={() => onShowSkiLocker(skiLockerData)}
                                hasSkiLockerTeaser={hasSkilockerTeaser}
                                priceGroups={pricingConfigurations && pricingConfigurations.priceGroups ? pricingConfigurations.priceGroups : getPriceGroupsOfSelectedProductChoice()}
                                configurationSuccess={configurationSuccess}
                                options={serviceConfigurations ? serviceConfigurations.options : null}
                            />
                        </Fragment>
                    ) : ""}
                </div>
            </div>

            {calculateSteps() - 1 !== currentStep && getStepView()[currentStep] !== 'sector-list' ? (
                <SkiTicketFooterBar
                    isInEdit={isInEdit}
                    currentStep={currentStep}
                    onChangeStep={setCurrentStep}
                    onSubmit={submitConfiguration}
                    stepViews={getStepView()}
                    maxSteps={calculateSteps()}
                    selectedDate={selectedDateString}
                    ticketCounter={ticketCounter}
                    vipPasses={vipPasses}
                    allVipPassesValid={allVipPassesValid}
                />
            ) : ""}
        </Modal>
    )
}