/** @jsx h */

import {h} from "preact";
import {getSumOfObjectKeys, noop} from "../../utils/utils";
import {translate} from '@elements/translations';

export default function SkiLockerFooterBar({
     isInEdit = false,
     currentStep = 0,
     onChangeStep = noop(),
     onSubmit = noop(),
     stepViews = [],
     maxSteps = 0,
     selectedDate = "",
     selectedToDate = "",
     selectedSeason = "",
     selectedLocation = "",
     ticketCounter = {}
 }) {
    return (
        <div className="ticket-configuration__footer ab-mobile-configurator__footer">
            <div className="container-xx-large h-100">
                <div className="row h-100">
                    {currentStep > 0 ? (
                        <div className="col-4 d-flex">
                            <button className="btn btn-no-styling"
                                    onClick={() => {
                                        onChangeStep(currentStep - 1);
                                    }}>
                                <span className="icon icon-arrow-long me-1" aria-hidden={true}/>
                                {translate(`ski-locker.back`)}
                            </button>
                        </div>
                    ) : ""}
                    <div className="col-8 ms-auto d-flex justify-content-end align-items-center">
                        {currentStep < maxSteps - 2 ? (
                            //ski-locker always needs selectedDate + selectedToDate
                            <button className="btn btn-primary ticket-configuration__footer-btn"
                                    disabled={
                                        (stepViews[currentStep] === 'datepicker' && ((!selectedDate || !selectedToDate) && !selectedSeason)) ||
                                        (stepViews[currentStep] === 'location-list' && !selectedLocation)||
                                        (stepViews[currentStep] === 'pricing-list' && getSumOfObjectKeys(ticketCounter) === 0)
                                    }
                                    onClick={() => {
                                        onChangeStep(currentStep + 1);
                                    }}>
                                {translate(`ski-locker.continue`)}
                            </button>
                        ) : (
                            <button className="btn btn-primary ticket-configuration__footer-btn"
                                    disabled={
                                        (stepViews[currentStep] === 'pricing-list' && getSumOfObjectKeys(ticketCounter) === 0)
                                    }
                                    onClick={() => {
                                        onSubmit()
                                    }}>
                                {isInEdit ? translate(`ski-locker.update-ticket`) : translate(`ski-locker.add-to-cart`) }
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}