/** @jsx h */

import {h, Fragment} from "preact";
import {useEffect, useState} from "preact/hooks";
import {getRangeDays, noop, throwAlertMessage} from "../../utils/utils";
import {
    usePricingConfigurations,
    usePricingSliderConfigurations,
    useSectorConfigurations,
    useServiceConfigurations
} from "../../utils/ski-ticket-configuration-hooks";
import LoadingSpinner from "../loading-spinner";
import PricingSlider from "../pricing-slider";
import {assert, object, optional, string, array, boolean, number, enums} from 'superstruct';
import ServiceList from "../service-list";
import PricingSummary from "../pricing-summary";
import {translate} from '@elements/translations';

export default function SkiTicketFinalSelection({
    id = "",
    title = "",
    selectedSectorTitle  ="",
    selectedDate = "",
    selectedToDate = "",
    onSelectDate = noop(),
    selectedRangeInDays = 1,
    ticketCounter = {},
    selectedProductChoice = "",
    selectedOptions = {},
    onChangeOptions = noop(),
    showApplyServiceForAllTickets = true,
    hasServiceView = true,
    hasPricingSlider= true,
    selectedSector="",
    onChangeShowApplyServiceForAllTickets = noop(),
    vipPasses=[]
}) {
    const ServiceItemType = object({
        id: string(),
        label: string(),
        type: enums(['radio', 'checkbox']),
        description: optional(string()),
        options: array()
    });

    const ServicePriceConfigurationType = object({
        options: array(),
        price: number(),
        basePrice: optional(number()),
        discountText: optional(string())
    });

    const ServiceOptionType = object({
        id: string(),
        label: string(),
        info: optional(string()),
        available: optional(number),
        status: enums(['success','warning','danger'])
    })

    const PricingSliderDateType = object({
        date: string(),
        defaultPrice: number(),
        isPriceOfTheWeek: boolean(),
        priceByPriceGroups: object()
    });

    let params = {
        ticket_id: id,
        ...(selectedDate && { startDate: selectedDate }),
        ...(selectedToDate && { endDate: selectedToDate }),
        ...(vipPasses && {vipPasses: vipPasses}),
        ...(selectedProductChoice && {productChoice: selectedProductChoice}),
        ...(selectedSector && { sector: selectedSector })
    }

    function getOption(item) {
        let option;
        serviceConfigurations.options.map(function(optionItem) {
            if(optionItem.id === item) {
                option = optionItem;
            }
        })
        return option;
    }

    function getAvailabilityOfOption(option, tempObj) {
        let selectedOptionCounter = 0;

        let maxAvailability = getOption(option).available;
        if(maxAvailability && maxAvailability > 0) {
            Object.keys(tempObj).map(function(priceGroup,key) {
                let tempArray = [...tempObj[priceGroup]];
                if(tempArray.includes(option)) {
                    selectedOptionCounter++;
                }
            });
        }

        return maxAvailability - selectedOptionCounter;
    }

    //prefill selected options -> first radio of radio-group(service) is selected
    function fillDefaultSelectedOptions() {
        let tempObj = {};

        if(selectedOptions && selectedOptions.constructor === Object) {

            //set first option by default
            Object.keys(ticketCounter).map(function(priceGroup,key) {
                [...Array(ticketCounter[priceGroup])].map(function(x, i) {
                    if(selectedOptions[priceGroup + "-" + i] === undefined || selectedOptions[priceGroup + "-" + i].length === 0) {
                        serviceConfigurations['servicesByPriceGroup'][priceGroup].map(function(service) {
                            let checkOptionIndex = 0;
                            service.options.map(function(option, optionIndex) {
                                if(optionIndex === checkOptionIndex && service.type === 'radio') {
                                    let tempOption = getOption(option);
                                    if(tempOption && tempOption.available) {
                                        if(tempOption.available > 0 && getAvailabilityOfOption(option, tempObj) > 0) {
                                            let tempArray = tempObj[priceGroup + "-" + i] || [];
                                            tempArray.push(option);
                                            tempObj = {...tempObj, [priceGroup + "-" + i]: tempArray};
                                        } else {
                                            checkOptionIndex++;
                                        }
                                    } else {
                                        let tempArray = tempObj[priceGroup + "-" + i] || [];
                                        tempArray.push(option);
                                        tempObj = {...tempObj,[priceGroup + "-" + i]: tempArray};
                                    }
                                }
                            });
                        });
                    }
                });
            });

            onChangeOptions({...selectedOptions, ...tempObj});
        }
    }

    function getPriceGroupsOfSelectedProductChoice() {
        let priceGroups = [];

        if(!pricingConfigurations.productChoices) {
            throwAlertMessage('danger', 'pricingConfigurations.productChoices', 'not defined!', true);
        } else {
            pricingConfigurations.productChoices.map(function (productChoice) {
                if(productChoice.id === selectedProductChoice) {
                    priceGroups =  productChoice.priceGroups;
                }
            });
        }

        return priceGroups;
    }

    let {serviceConfigurations, isLoadingService, errorService} = hasServiceView ? useServiceConfigurations(params) : {serviceConfigurations: null, isLoadingService: false};
    let {pricingConfigurations, isLoadingPricing, errorPricing} = usePricingConfigurations(params);
    let {pricingSliderConfigurations, isLoadingSlider, errorPricingSlider} = hasPricingSlider ? usePricingSliderConfigurations(params) : {pricingSliderConfigurations: null, isLoadingSlider: false};

    useEffect(function() {
        if(serviceConfigurations) {
            fillDefaultSelectedOptions(serviceConfigurations);
        }
    }, [serviceConfigurations, ticketCounter]);

    return (
        <Fragment>
            { errorService || errorPricing || errorPricingSlider ? (
                <div className="alert alert-danger">
                    {translate('ski-ticket.Loading-Error')}
                </div>
            ) : ""}
            { isLoadingPricing || isLoadingService || isLoadingSlider || hasServiceView && !Object.keys(selectedOptions).length > 0 ? (
                <LoadingSpinner/>
            ) : (
                <div className="row">
                    <div className="col-md-8">
                        {pricingSliderConfigurations ? (
                            <div className="mb-5">
                                <PricingSlider
                                    pricingSliderConfigurations={pricingSliderConfigurations.priceByDate}
                                    selectedDate={selectedDate}
                                    selectedToDate={selectedToDate}
                                    PricingSliderDateType={PricingSliderDateType}
                                    onSelectDate={onSelectDate}
                                    selectedRangeInDays={getRangeDays(selectedDate, selectedToDate)}
                                    ticketCounter={ticketCounter}
                                />
                            </div>
                        ) : ""}

                        { serviceConfigurations && pricingConfigurations ? (
                            <ServiceList
                                data={serviceConfigurations}
                                priceGroups={pricingConfigurations && pricingConfigurations.priceGroups ? pricingConfigurations.priceGroups : getPriceGroupsOfSelectedProductChoice()}
                                ticketCounter={ticketCounter}
                                selectedOptions={selectedOptions}
                                onChangeOptions={onChangeOptions}
                                ServiceItemType={ServiceItemType}
                                ServicePriceConfigurationType={ServicePriceConfigurationType}
                                ServiceOptionType={ServiceOptionType}
                                hideTotal={true}
                                showApplyServiceForAllTickets={showApplyServiceForAllTickets}
                                onChangeShowApplyServiceForAllTickets={onChangeShowApplyServiceForAllTickets}
                            />
                        ) : "" }
                    </div>
                </div>
            )}
        </Fragment>
    )
}