/** @jsx h */

import {h} from "preact";

export default function SkiLockerHeader({
    title = "",
    toptitle = "",
    currentStep = 0,
    subtitle = "",
    isInEdit = false
}) {
    return (
        <div className="ticket-configuration__title-block">
            <div className="h2 ticket-configuration__title">
                <span className="me-1">
                    {currentStep + 1}.
                </span> {toptitle}

                {isInEdit ? (
                    <span className="badge bg-primary ticket-configuration__edit-badge">
                        In Edit
                    </span>
                ) : ""}
            </div>

            {subtitle ? (
                <div className="h3 ticket-configuration__subtitle">
                    {subtitle}
                </div>
            ) : ""}
        </div>
    )
}